@import '../node_modules/@sodra/bongo-ui/dark.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background: var(--background);
  color: var(--on-background);
}

* {
  box-sizing: border-box;
}

select, option {
  color: var(--on-surface);
  background: var(--container-background);
}

.bui {
  --hue: 125;
  --saturation: 93%;
  --lightness: 62%;

  --background: hsl(var(--hue) 1% 6%);
  --surface: hsl(var(--hue) 1% 6%);
  --surface-floating: hsl(var(--hue) 1% 11%);
  --container-background: hsla(var(--hue), 5%, 16%, 0.8);
  --container-outline: hsla(var(--hue), 5%, 26%, 0.8);
  --container-outline-lighter: hsla(var(--hue), 5%, 23%, 0.8);

  --data-table-hover: hsl(var(--hue), 5%, 13%); /* Needs to be opaque */
  --data-table-selected: hsl(var(--hue), 75%, 22%); /* Needs to be opaque */

  --inverted-surface: hsl(var(--hue) 10% 20%);
  --surface-alternative: hsl(var(--hue) 5% 13%);

  --surface-highlight: hsl(var(--hue) 40% 75%);

  --accent: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.05));
  --accent-text: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.05));
  --accent-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1));
  --accent-light: hsl(var(--hue) calc(var(--saturation) * 0.37) calc(var(--lightness) * 0.4));
  --on-accent: #000;
  --accent-highlight: hsl(var(--hue) 50% 25%);
  
  --selection-control: hsl(var(--hue) 10% 36%);
  --selection-control-selected: var(--accent-surface);
  --on-selection-control-selected: hsl(var(--hue) calc(var(--saturation) * 0.37) calc(var(--lightness) * 0.55));
  --on-selection-control-selected: hsl(var(--hue) calc(var(--saturation) * 0.37) calc(var(--lightness) * 0.35));

  --error: hsl(var(--error-hue) var(--saturation) 46%);
  --error-text: hsl(var(--error-hue) var(--saturation) 54%);
  --error-highlight: hsla(var(--error-hue), 50%, 100%, 0.75);

  --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * 0.38) 45%);
  --warning-text: hsl(var(--warning-hue) calc(var(--saturation) * 0.7) 48%);

  --navigation-accent-highlight: #000;
  --on-navigation-accent-resting: hsl(var(--hue) 100% 25%);
  --on-navigation-accent-active: #000;

  --scrollbar-thumb: hsl(var(--hue) calc(var(--saturation) * 0.2) calc(var(--lightness) * 0.8));

  --button-contained-font-weight: 500;
}
