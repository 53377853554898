:target {
   animation: highlight ease;
   animation-duration: 1s;
}

@keyframes highlight {
    from {
        background: var(--accent-text);
        border-radius: 10px;
    }

    to {
        background: inherit;
        border-radius: inherit;
    }
}